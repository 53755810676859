import * as types from './constants';

export const setTourTypeActionBackend = tourType => ({
  type: types.SET_TOUR_TYPE_BACKEND,
  payload: {
    tourType,
  },
});

export const setTourTypeActionHowToHub = tourType => ({
  type: types.SET_TOUR_TYPE_HOW_TO_HUB,
  payload: {
    tourType,
  },
});

export const finishTourAction = () => ({
  type: types.FINISH_TOUR,
});

export const notifyAboutRender = name => ({
  type: types.COMPONENT_RENDERED,
  payload: {
    name,
  },
});
