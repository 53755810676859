import config from '../config';

type Features = {
  quicksight: boolean;
  sso: boolean;
  ownerGroup: boolean;
  ownerGroupPage: boolean;
  offerCodes: boolean;
  templateLooks: boolean;
  landingPage: boolean;
  embeddableWidgets: boolean;
  jiraIssueCollector: boolean;
  shareableLinks: boolean;
  artistsTab: boolean;
  reinviteUser: boolean;
  tour: boolean;
  adminCanEditLooks: boolean;
  lookExpirationDate: boolean;
  brandLookEditable: boolean;
  salonBio: boolean;
};

const featureFlags: () => Features = () => config.featureFlags;

const isQuicksightEnabled = () => featureFlags().quicksight;

const isSsoEnabled = () => featureFlags().sso;

const isOwnerGroup = () => featureFlags().ownerGroup;

const isOwnerGroupPage = () => featureFlags().ownerGroupPage;

const isOfferCodes = () => featureFlags().offerCodes;

const isTemplateLooks = () => featureFlags().templateLooks;

const isLandingPage = () => featureFlags().landingPage;

const isEmbeddableWidgets = () => featureFlags().embeddableWidgets;

const isJiraIssueCollector = () => featureFlags().jiraIssueCollector;

const isShareableLinks = () => featureFlags().shareableLinks;

const isArtistsTab = () => featureFlags().artistsTab;

const isReinviteUser = () => featureFlags().reinviteUser;

const isTour = () => featureFlags().tour;

const isAdminCanEditLooks = () => featureFlags().adminCanEditLooks;

const isLookExpirationDate = () => featureFlags().lookExpirationDate;

const isBrandLookEditable = () => featureFlags().brandLookEditable;

const isSalonBio = () => featureFlags().salonBio;

export {
  isQuicksightEnabled,
  isSsoEnabled,
  isOwnerGroup,
  isOwnerGroupPage,
  isOfferCodes,
  isTemplateLooks,
  isLandingPage,
  isEmbeddableWidgets,
  isJiraIssueCollector,
  isShareableLinks,
  isArtistsTab,
  isReinviteUser,
  isTour,
  isAdminCanEditLooks,
  isLookExpirationDate,
  isBrandLookEditable,
  isSalonBio,
};
