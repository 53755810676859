/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Col, Image, Button } from 'react-bootstrap';
import { RetailerForm } from 'containers/forms';
import config from 'config';
import _, { isNil, omit } from 'lodash';
import { entityActions } from 'redux/entities/actions';
import { redirectCell, redirectCellV4 } from 'functions/redirectCell';
import EditVariantComponent from 'containers/ProductVariant/EditVariant';
import * as moment from 'moment';
import { FeedbackPrompt, Form, Modal, QrCode } from 'components';
import history from 'utils/history';
import PrimaryVariantCheckbox from 'containers/ProductVariant/PrimaryVariantCheckbox';
import { BiInfoCircle } from 'react-icons/bi';
import { IoMdShare } from 'react-icons/io';
import EntityService from 'api/services/EntityService';
import ApproveService from 'api/services/ApproveService';
import { useDispatch } from 'react-redux';
import { toastr } from 'utils';
import { push } from 'connected-react-router';
import { MdCheckCircle, MdEdit, MdOutlineRemoveRedEye, MdOutlineAddToPhotos } from 'react-icons/md';
import { FaTimesCircle } from 'react-icons/fa';
import { selectLocation } from 'redux/locations/actions';
import { Tooltip } from '@material-ui/core';
import { openModalAction } from 'redux/modal/actions';
import { SHARE_MODAL_ID } from 'pages/Locations/modals/constants';
import { BsEye } from 'react-icons/bs';
import ShareLinkModal from '../pages/ShareableLinks/ShareLinkModal';
import i18n from '../i18n';
import { CREATE_PRODUCT, PRODUCTS, PRODUCTS_GROUPS, RETAILERS, TIERS, REGIONS } from './routes';
import retailerStatus from './retailerStatus';
import stylistStatus from './stylistStatus';
import store from '../configureStore';
import entities from './entities';
import Placeholder from '../assets/img/covers/placeholder.jpg';
import avedaPlaceholder from '../assets/img/aveda-placeholder.png';
import { brands } from './brands';
import { ReactComponent as Reject } from '../assets/svg/reject.svg';
import { ReactComponent as Publish } from '../assets/svg/publish.svg';
import SelectLocationAndAddModuleModal from '../pages/ShareableLinks/SelectLocationAndAddModuleModal';
import { isReinviteUser } from '../utils/featureFlags.ts';

const formatDate = (cellValue, withTime = false) => {
  const momentDate = moment(cellValue);
  const state = store.getState();
  const timeAppend = withTime ? ' HH:mm' : '';

  if (!cellValue) {
    return null;
  }
  // zero brand is aveda
  if (state.auth.user.brand.preview === brands[0]) {
    // return date in US format
    return momentDate.format(`MM/DD/YYYY${timeAppend}`);
  }
  // return date in EU format
  return momentDate.format(`DD/MM/YYYY${timeAppend}`);
};

const getBadge = status => {
  switch (status) {
    case retailerStatus.ACTIVE:
      return 'success';
    case retailerStatus.INACTIVE:
      return 'secondary';
    case retailerStatus.REJECTED:
      return 'warning';
    case retailerStatus.BANNED:
      return 'danger';
    case retailerStatus.INVITATION_PENDING:
      return 'info';
    default:
      break;
  }
};

const getStylistBadge = retailer => {
  if (retailer.is_archived) {
    return 'danger';
  }

  switch (retailer.profile_page_status) {
    case stylistStatus.APPROVED:
      return 'success';
    case stylistStatus.NEW_FEEDBACK:
    // fallthrough
    case stylistStatus.WAIT_FOR_FEEDBACK:
      return 'warning';
    case stylistStatus.SUBMIT_FOR_APPROVAL:
    // fallthrough
    case stylistStatus.REJECTED:
    // fallthrough
    default:
      return 'danger';
  }
};

const getPageStatusString = retailer => {
  if (retailer.is_archived) {
    return i18n.t('ownerPortal:allStylists.archived');
  }
  switch (retailer.profile_page_status) {
    case stylistStatus.APPROVED:
      return i18n.t('ownerPortal:allStylists.approved');
    case stylistStatus.WAIT_FOR_FEEDBACK:
      return i18n.t('ownerPortal:allStylists.review');
    case stylistStatus.REJECTED:
      return i18n.t('ownerPortal:allStylists.rejected');
    case stylistStatus.NEW_FEEDBACK:
      return i18n.t('ownerPortal:allStylists.newFeedback');
    case stylistStatus.SUBMIT_FOR_APPROVAL:
      return i18n.t('ownerPortal:allStylists.pageNotSubmitted');
    default:
      return i18n.t('ownerPortal:allStylists.defaultStatus');
  }
};

const columns = {
  benefit: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },
    {
      Header: i18n.t('app:description'),
      accessor: 'description',
    },
    {
      Header: i18n.t('pages:product.products'),
      accessor: 'products',
      renderCell: cell => redirectCell(cell, PRODUCTS),
    },
  ],
  brand: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:entities.brand'),
      accessor: 'name',
    },
    {
      Header: i18n.t('app:description'),
      accessor: 'description',
    },
    {
      Header: i18n.t('pages:product.products'),
      accessor: 'products',
      renderCell: cell => redirectCell(cell, PRODUCTS),
    },
  ],
  bundle: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },

    {
      Header: i18n.t('pages:product.products'),
      accessor: 'products',
      renderCell: cell => redirectCell(cell, PRODUCTS),
    },
  ],
  claim: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },

    {
      Header: i18n.t('pages:product.products'),
      accessor: 'products',
      renderCell: cell => redirectCell(cell, PRODUCTS),
    },
  ],
  color: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },
    {
      Header: 'hex',
      accessor: 'hex_value',
    },
    {
      Header: i18n.t('app:entities.color'),
      accessor: '',
      renderCell: data => (
        <div style={{ width: 32, height: 32, backgroundColor: data.row.original.hex_value }} />
      ),
    },
    {
      Header: i18n.t('pages:product.products'),
      accessor: 'products',
      renderCell: cell => redirectCell(cell, PRODUCTS),
    },
  ],

  color_category: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },
  ],
  category: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
      renderCell: cell => (
        // eslint-disable-next-line
        <p className="m-0" dangerouslySetInnerHTML={{ __html: cell.row.original.name }} />
      ),
    },

    {
      Header: i18n.t('pages:product.products'),
      accessor: 'products',
      renderCell: cell => redirectCell(cell, PRODUCTS),
    },
  ],
  gtin: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },
    {
      Header: i18n.t('pages:product.products'),
      accessor: 'products',
      renderCell: cell => redirectCell(cell, PRODUCTS),
    },
  ],
  ingredient: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:entities.ingredient'),
      accessor: 'name',
    },
    {
      Header: i18n.t('app:description'),
      accessor: 'description',
    },
    {
      Header: i18n.t('pages:product.products'),
      accessor: 'products',
      renderCell: cell => redirectCell(cell, PRODUCTS),
    },
  ],
  multipack: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },

    {
      Header: i18n.t('pages:product.products'),
      accessor: 'products',
      renderCell: cell => redirectCell(cell, PRODUCTS),
    },
  ],
  pattern: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },

    {
      Header: i18n.t('pages:product.products'),
      accessor: 'products',
      renderCell: cell => redirectCell(cell, PRODUCTS),
    },
  ],
  product: [
    {
      sortable: false,
      accessor: 'category_name',
      renderCell: () => null,
    },
    {
      Header: 'id',
      accessor: 'id',
      filter: 'multiple',
    },
    {
      Header: 'Region',
      accessor: 'region',
      reference: { entity: entities.region, property: 'name' },
      sortable: false,
    },
    // {
    //   Header: i18n.t('app:entities.product_group'),
    //   accessor: 'product_groups',
    //   renderCell: cell => redirectCell(cell, PRODUCTS_GROUPS),
    // },
    {
      sortable: false,
      Header: i18n.t('app:image'),
      accessor: 'image',
      renderCell: cell => (
        <img
          src={cell?.value[0] || Placeholder}
          style={{ width: 50, height: 50 }}
          alt="product preview"
        />
      ),
    },

    {
      Header: i18n.t('app:title'),
      accessor: 'name',
      renderCell: cell => (
        // eslint-disable-next-line
        <p className="m-0" dangerouslySetInnerHTML={{ __html: cell.row.original.name }} />
      ),
    },
    {
      sortable: false,
      Header: i18n.t('app:description'),
      accessor: 'description',
      renderCell: cell => (
        // eslint-disable-next-line
        <p className="m-0" dangerouslySetInnerHTML={{ __html: cell.row.original.description }} />
      ),
    },
    {
      Header: i18n.t('app:entities.category'),
      accessor: 'category',
      reference: { entity: entities.category, property: 'name' },
      renderCell: cell => {
        const { entities: libraries } = store.getState();
        const { data } = libraries[cell.column.reference.entity];

        const reference = data.find(
          item => item.id === cell.row.original[cell.column.reference.entity],
        );

        if (!reference) return null;
        return (
          <p
            className="m-0"
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{ __html: reference[cell.column.reference.property] }}
          />
        );
      },
    },
    // {
    //   Header: i18n.t('app:entities.type'),
    //   accessor: 'type',
    // },
  ],
  product_group: [
    {
      Header: 'id',
      accessor: 'id',
      filter: 'multiple',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },
    {
      Header: 'Region',
      accessor: 'region',
      reference: { entity: entities.region, property: 'name' },
      sortable: false,
    },
    {
      Header: i18n.t('pages:product.products'),
      accessor: 'products',
      renderCell: cell => redirectCellV4(cell, PRODUCTS, 'product_group_id'),
    },
    {
      Header: i18n.t('app:entities:tier'),
      sortable: false,
      accessor: 'tier_id',
      renderCell: () => null,
    },
    {
      Header: i18n.t('pages:tiers.tiers'),
      accessor: 'tiers',
      renderCell: cell => redirectCell(cell, TIERS),
    },
    {
      Header: i18n.t('pages:retailers.retailers'),
      accessor: 'retailers',
      renderCell: cell => redirectCellV4(cell, RETAILERS, 'product_group_id'),
    },
  ],
  region: [
    {
      Header: 'id',
      accessor: 'id',
      filter: 'multiple',
    },
    {
      Header: i18n.t('app:entities.region'),
      accessor: 'name',
    },
    {
      Header: i18n.t('app:currency'),
      accessor: 'base_currency',
    },
    {
      Header: i18n.t('pages:regions.shippingCountry'),
      accessor: 'shipping_country',
    },
    {
      Header: i18n.t('pages:retailers.retailers'),
      accessor: 'retailers',
      renderCell: cell => redirectCell(cell, RETAILERS),
    },
    {
      Header: i18n.t('app:entities.product_group'),
      accessor: 'product_groups',
      renderCell: cell => redirectCell(cell, PRODUCTS_GROUPS),
    },
  ],
  retailer: [
    {
      Header: 'id',
      accessor: 'id',
      filter: 'multiple',
    },
    {
      sortable: false,
      accessor: 'location_name',
      renderCell: () => null,
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },

    {
      Header: i18n.t('app:email'),
      accessor: 'email',
    },
    {
      sortable: false,
      accessor: 'test_user_flag',
      renderCell: () => null,
    },
    {
      Header: i18n.t('app:entities.region'),
      accessor: 'region',
      reference: { entity: entities.region, property: 'name' },
    },
    {
      Header: () => (
        <Tooltip title={i18n.t('pages:retailers.lastLoginTooltip')} placement="right">
          <span>
            {i18n.t('app:entities.last_login')}
            <BiInfoCircle className="mb-4" size={15} />
          </span>
        </Tooltip>
      ),
      sortable: true,
      accessor: 'connected_user.last_login',
      renderCell: cell => formatDate(cell.value, true),
    },
    // {
    //   Header: i18n.t('app:entities.product_group'),
    //   accessor: 'product_groups',
    //   renderCell: cell => redirectCell(cell, PRODUCTS_GROUPS),
    // },
    {
      sortable: true,
      Header: i18n.t('columns:status'),
      accessor: 'status',
      renderCell: cell => (
        <span className={`badge bg-${getBadge(cell.row.original.status)}`}>
          {cell.row.original.status}
        </span>
      ),
    },
    {
      sortable: true,
      Header: i18n.t('columns:pageStatus'),
      accessor: 'profile_page_status',
      renderCell: cell => (
        <span className={`badge text-uppercase bg-${getStylistBadge(cell.row.original)}`}>
          {getPageStatusString(cell.row.original)}
        </span>
      ),
    },
    {
      sortable: false,
      Header: 'Access',
      accessor: 'statusChange',
      renderCell: cell => {
        const updateRetialerStatus = (uid, status) => {
          store.dispatch(
            entityActions.updateEntity('retailer', {
              values: { uid, status },
            }),
          );
        };

        switch (cell.row.original.status) {
          case retailerStatus.ACTIVE:
            return (
              <Button
                variant="danger"
                size="sm"
                style={{ minWidth: '80%' }}
                onClick={() => updateRetialerStatus(cell.row.original.uid, retailerStatus.BANNED)}
                disabled={cell.row.original.is_archived}
              >
                {i18n.t('pages:retailers.block')}
              </Button>
            );
          case retailerStatus.REJECTED:
            return (
              <Modal
                className="modal-lg"
                trigger={
                  <Button style={{ minWidth: '80%' }} variant="info" size="sm">
                    {i18n.t('pages:retailers.invite')}
                  </Button>
                }
                header={{
                  title: i18n.t('pages:retailers.inviteRetailer'),
                  subTitle: i18n.t('pages:retailers.retailers'),
                }}
              >
                <RetailerForm
                  type="update"
                  initialValues={{
                    ...omit(cell.row.original, 'id'),
                    status: retailerStatus.INVITATION_PENDING,
                  }}
                  FormGroup={Form.GroupVertical}
                  wrapperType="modal"
                />
              </Modal>
            );
          case retailerStatus.BANNED:
            return (
              <Button
                variant="success"
                size="sm"
                style={{ minWidth: '80%' }}
                onClick={() => updateRetialerStatus(cell.row.original.uid, retailerStatus.ACTIVE)}
              >
                {i18n.t('pages:retailers.active')}
              </Button>
            );
          case retailerStatus.INVITATION_PENDING:
            return (
              <Button
                variant="warning"
                size="sm"
                style={{ minWidth: '80%' }}
                onClick={() => updateRetialerStatus(cell.row.original.uid, retailerStatus.REJECTED)}
              >
                {i18n.t('pages:retailers.reject')}
              </Button>
            );
          default:
            break;
        }
      },
    },
  ],
  size: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },
    {
      Header: i18n.t('app:description'),
      accessor: 'description',
    },
    {
      Header: i18n.t('pages:product.products'),
      accessor: 'products',
      renderCell: cell => redirectCell(cell, PRODUCTS),
    },
  ],
  sku: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:entities.sku'),
      accessor: 'sku',
    },
    {
      Header: i18n.t('app:description'),
      accessor: 'description',
    },
  ],

  variant: [
    {
      Header: 'id',
      accessor: 'id',
      renderCell: cell => <EditVariantComponent cell={cell} />,
    },
    {
      Header: i18n.t('app:entities.upcCode'),
      accessor: 'upc_code',
    },
    {
      Header: i18n.t('app:entities.color'),
      accessor: 'color',
      renderCell: cell => <p className="m-0">{cell?.value?.name}</p>,
    },
    {
      Header: i18n.t('app:entities.size'),
      accessor: 'size',
      renderCell: cell => <p className="m-0">{cell?.value?.name}</p>,
    },
    {
      Header: i18n.t('pages:createProduct.price'),
      accessor: 'retail_price',
    },
    {
      Header: i18n.t('pages:createProduct.isPrimarySku'),
      accessor: 'is_primary',
      renderCell: cell => (
        <PrimaryVariantCheckbox
          variant={cell.row.original}
          editable={cell.row.original.is_editable}
        />
      ),
    },
  ],

  tier: [
    {
      Header: 'id',
      accessor: 'id',
      filter: 'multiple',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },

    {
      Header: i18n.t('app:users'),
      accessor: 'retailers',
      renderCell: cell => redirectCellV4(cell, RETAILERS, 'tier_id'),
    },

    {
      Header: i18n.t('app:entities.product_group'),
      accessor: 'product_groups',
      renderCell: cell => redirectCell(cell, PRODUCTS_GROUPS),
    },
  ],
  theme: [
    {
      Header: 'id',
      accessor: 'id',
      filter: 'multiple',
    },
    {
      Header: i18n.t('pages:theme.coverPhoto'),
      accessor: 'cover_photo',
      renderCell: cell => (
        <div
          style={{
            backgroundImage: `url(${cell.value?.url})`,
            height: '60px',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        />
      ),
    },
    {
      Header: i18n.t('pages:theme.themeName'),
      accessor: 'name',
    },
  ],
  admins: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },

    {
      Header: i18n.t('app:email'),
      accessor: 'email',
    },
    {
      Header: i18n.t('app:entities.region'),
      accessor: 'regions',
      renderCell: cell => redirectCell(cell, REGIONS),
    },
    {
      Header: i18n.t('app:entities.brand'),
      accessor: 'brand',
      reference: { entity: entities.brand, property: 'name' },
    },
    {
      Header: i18n.t('columns:status'),
      accessor: 'status',
      renderCell: cell => (
        <span className={`badge bg-${getBadge(cell.row.original.status)}`}>
          {cell.row.original.status}
        </span>
      ),
    },
  ],

  drafts: [
    {
      Header: i18n.t('columns:draftNr'),
      accessor: 'draftNr',
      renderCell: cell => (
        <Button
          variant="link"
          onClick={() => history.push(CREATE_PRODUCT, { initialValues: cell.row.original })}
        >
          {cell.row.original.draftNr}
        </Button>
      ),
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },
    {
      Header: i18n.t('columns:lastUpdate'),
      accessor: 'lastUpdate',
      renderCell: cell => formatDate(cell.value, true),
    },
  ],
  mediaResource: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      sortable: false,
      Header: i18n.t('app:image'),
      accessor: 'url',
      renderCell: cell => {
        const type = cell.row.original.resource_type;
        const isVideo = type === 'video' || type === 'youtube';
        return (
          <img
            className="rounded"
            src={isVideo ? cell.row.original.thumbnail_url : cell.value}
            style={{ maxHeight: '90px', maxWidth: '120px' }}
            alt="resource preview"
          />
        );
      },
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },
    {
      Header: 'Region',
      accessor: 'region',
      reference: { entity: entities.region, property: 'name' },
      sortable: false,
    },
    {
      Header: 'Expiration Date',
      accessor: 'is_deleted',
      sortType: (a, b) => {
        if (!a.original.expiration_date && b.original.expiration_date) return 0;

        const aExpDate = new Date(a.original.expiration_date);
        const bExpDate = new Date(b.original.expiration_date);
        return aExpDate - bExpDate;
      },
      renderCell: cell => (
        <span style={cell.row.original.expired ? { color: 'red' } : {}}>
          {formatDate(cell.row.original.expiration_date, false)}
        </span>
      ),
    },
    {
      Header: i18n.t('columns:resourceType'),
      accessor: 'resource_type',
      renderCell: cell => (
        <Col>
          <p className="pb-0 mb-0">{cell.value}</p>
        </Col>
      ),
    },
  ],
  stylists: [
    { accessor: 'filterColumn', renderCell: () => null, sortable: false },
    {
      accessor: 'profile_img',
      sortable: false,
      renderCell: cell => (
        <Image
          className="rounded-circle bg-secondary"
          style={{ width: '80px', height: '80px' }}
          src={cell.row.original.connected_user?.picture || Placeholder}
        />
      ),
    },
    {
      Header: i18n.t('ownerPortal:allStylists.name'),
      accessor: 'name',
      renderCell: cell => {
        const name = cell.row.original.connected_user?.name || cell.row.original.name;
        const surename = cell.row.original.connected_user?.last_name || cell.row.original.surename;
        return <p className="m-0">{`${name || ''} ${surename || ''}`}</p>;
      },
    },
    {
      Header: i18n.t('ownerPortal:allStylists.salon'),
      accessor: 'salonName',
    },
    {
      Header: i18n.t('ownerPortal:allStylists.looks'),
      accessor: 'stores',
      renderCell: cell => <p className="text-center m-0 pr-4">{cell.value}</p>,
    },
    {
      Header: i18n.t('ownerPortal:allStylists.views'),
      accessor: 'views',
    },
    {
      Header: i18n.t('ownerPortal:allStylists.status'),
      accessor: 'profile_page_status',
      sortType: (a, b) => {
        const aString = getPageStatusString(a.values);
        const bString = getPageStatusString(b.values);
        return aString.localeCompare(bString);
      },
      renderCell: cell => (
        <>
          <span className={`badge bg-${getStylistBadge(cell.row.original)} p-2 mx-1`}> </span>
          <span>{getPageStatusString(cell.row.original)}</span>
        </>
      ),
    },
    {
      Header: 'Last Login',
      sortable: true,
      accessor: 'connected_user.last_login',
      renderCell: cell => formatDate(cell.value, true),
    },
    {
      Header: i18n.t('ownerPortal:allStylists.webShoppe'),
      sortable: false,
      accessor: 'url',
      renderCell: cell => {
        const { profile_page_status, vanity_url, uid } = cell.row.original;
        const openLink = async (review = false) => {
          // open url in new tab
          const { url } = await EntityService.generateLink(vanity_url);
          const fullUrl = review ? `${url}&feedback=true&preview=true` : `${url}?preview=true`;
          window.open(fullUrl, '_blank');
        };

        switch (profile_page_status) {
          case stylistStatus.NEW_FEEDBACK:
          // fallthrough
          case stylistStatus.WAIT_FOR_FEEDBACK:
            // review page
            return (
              <Button
                style={{ minWidth: '80%' }}
                className="rounded-0 text-uppercase"
                variant="dark"
                onClick={() => openLink(true)}
              >
                <span className="text-small">{i18n.t('ownerPortal:allStylists.reviewPage')}</span>
              </Button>
            );
          case stylistStatus.APPROVED:
            return (
              <Button
                style={{ minWidth: '80%' }}
                className="rounded-0 text-uppercase"
                variant="dark"
                onClick={() => openLink(false)}
              >
                <span className="text-small">{i18n.t('ownerPortal:allStylists.viewPage')}</span>
              </Button>
            );
          case stylistStatus.REJECTED:
            return (
              <FeedbackPrompt
                trigger={
                  <p className="px-2 m-0">
                    <BsEye style={{ marginBottom: 5, marginRight: 5 }} />
                    View feedback
                  </p>
                }
                user={cell.row.original}
              />
            );
          case stylistStatus.SUBMIT_FOR_APPROVAL:
            return null;
          default:
            return (
              <Button
                style={{ minWidth: '80%' }}
                className="rounded-0 text-uppercase"
                variant="dark"
                disabled={!isReinviteUser()}
                onClick={() => {
                  ApproveService.sendInvite(uid);
                  toastr.success('Invite sent!');
                }}
              >
                <span className="text-small">{i18n.t('ownerPortal:allStylists.invite')}</span>
              </Button>
            );
        }
      },
    },
    {
      Header: i18n.t('ownerPortal:allStylists.actions'),
      sortable: false,
      centered: true,
      accessor: 'id',
      renderCell: cell => {
        const { profile_page_status, connected_user } = cell.row.original;
        const uid = connected_user?.uid || '';
        const isRejectVisible = [
          stylistStatus.NEW_FEEDBACK,
          stylistStatus.WAIT_FOR_FEEDBACK,
          stylistStatus.APPROVED,
        ].includes(profile_page_status);
        return (
          <>
            <div
              className="d-flex flex-row justify-content-center align-items-center"
              style={{ position: 'relative', top: '10px' }}
            >
              {[
                stylistStatus.NEW_FEEDBACK,
                stylistStatus.WAIT_FOR_FEEDBACK,
                stylistStatus.REJECTED,
              ].includes(profile_page_status) && (
                <div
                  onClick={async () => {
                    await ApproveService.approve(uid);
                    store.dispatch(
                      entityActions.updateEntitySuccess('location', {
                        uid: cell.row.original.locationUid,
                        participants: [
                          { ...cell.row.original, profile_page_status: stylistStatus.APPROVED },
                        ],
                      }),
                    );
                    toastr.success(i18n.t('toastr:updateSuccess'));
                  }}
                  className="d-flex flex-column"
                  style={{ marginRight: isRejectVisible ? '14px' : 0, cursor: 'pointer' }}
                >
                  <Publish height={45} width={45} />
                  <span className="text-small text-black-50 text-center">Publish</span>
                </div>
              )}
              {isRejectVisible && (
                <div
                  className="d-flex flex-column"
                  style={{ cursor: 'pointer' }}
                  onClick={async () => {
                    await ApproveService.disapprove(uid);
                    store.dispatch(
                      entityActions.updateEntitySuccess('location', {
                        uid: cell.row.original.locationUid,
                        participants: [
                          { ...cell.row.original, profile_page_status: stylistStatus.REJECTED },
                        ],
                      }),
                    );
                    toastr.success(i18n.t('toastr:updateSuccess'));
                  }}
                >
                  <Reject height={45} width={45} />
                  <span className="text-small text-black-50 text-center">Reject</span>
                </div>
              )}
            </div>
          </>
        );
      },
    },
  ],
  location: [
    {
      sortable: false,
      accessor: 'cover_photo',
      renderCell: cell => (
        <Image
          className="rounded"
          style={{ width: '120px', height: '80px', objectFit: 'cover' }}
          src={cell?.value || Placeholder}
        />
      ),
    },
    {
      Header: 'Salon Name',
      accessor: 'name',
      renderCell: cell => (
        <p className="m-0">
          {cell.row.original.name}
          <span className="m-0 ml-1 text-black-50">{cell.row.original.sap_id}</span>
        </p>
      ),
    },
    {
      Header: i18n.t('app:artists'),
      accessor: 'participants.length',
      // renderCell: cell => <p className="m-0 ml-2">{cell.row.original.participants.length}</p>,
    },
    {
      sortable: false,
      Header: i18n.t('ownerPortal:locations.location'),
      accessor: 'address',
      renderCell: cell => {
        const town = cell.row.original.address?.post_town?.toLowerCase() || '';
        const streetName = cell.row.original.address?.street_name?.toLowerCase() || '';
        const number = cell.row.original.address?.number_name?.toLowerCase() || '';
        const state = cell.row.original.address?.state?.toUpperCase() || '';

        return (
          <p
            style={{ textTransform: 'capitalize' }}
            className="m-0 ml-2"
          >{`${number} ${streetName} ${town}, ${state}`}</p>
        );
      },
    },
    {
      Header: i18n.t('app:views'),
      accessor: 'views_count',
    },
    {
      Header: i18n.t('app:actions'),
      sortable: false,
      accessor: 'uid',
      renderCell: cell => (
        <div className="tour-edit-preview-share d-flex" style={{ gap: '8px' }}>
          <div className="d-flex flex-column align-items-center" style={{ gap: '2px' }}>
            <Button
              variant="dark"
              className="edit rounded-0 text-center position-relative"
              style={{ padding: '6px 12px', border: 'none', outline: 'none', lineHeight: '20px' }}
              onClick={async () => {
                const selected = cell.row.original;

                store.dispatch(selectLocation(selected));
                store.dispatch(push(`/location/${cell.value}/page-edit`));
              }}
            >
              <MdEdit color="white" size={20} style={{ paddingRight: 0 }} />
              {!cell.row.original.is_edited && (
                <div
                  style={{
                    width: 13,
                    height: 13,
                    backgroundColor: '#EE3A3A',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: -6,
                    right: -6,
                  }}
                />
              )}
            </Button>
            <span style={{ fontSize: 10, fontWeight: 'bold', color: 'rgba(46, 46, 46, 0.8)' }}>
              Edit
            </span>
          </div>
          <div className="d-flex flex-column align-items-center" style={{ gap: '2px' }}>
            <Button
              variant="light"
              className="preview rounded-0 text-center"
              style={{
                background: 'white',
                padding: '6px 12px',
                outline: 'none',
                lineHeight: '20px',
                border: '1px solid var(--neutrals-500, #737373)',
              }}
              onClick={async () => {
                const selected = cell.row.original;

                window
                  .open(
                    `${config.avedaWebApp}/salon/${selected.vanity_url}/?preview=true`,
                    '_blank',
                  )
                  .focus();
              }}
            >
              <MdOutlineRemoveRedEye color="black" size={20} style={{ paddingRight: 0 }} />
            </Button>
            <span style={{ fontSize: 10, fontWeight: 'bold', color: 'rgba(46, 46, 46, 0.8)' }}>
              Preview
            </span>
          </div>
          <div className="d-flex flex-column align-items-center" style={{ gap: '2px' }}>
            <Button
              variant="primary"
              className="share rounded-0 text-center"
              style={{ padding: '6px 12px', border: 'none', outline: 'none', lineHeight: '20px' }}
              onClick={async () => {
                const selected = cell.row.original;
                store.dispatch(selectLocation(selected));
                store.dispatch(openModalAction(SHARE_MODAL_ID));
              }}
            >
              <IoMdShare color="white" size={20} style={{ paddingRight: 0 }} />
            </Button>
            <span style={{ fontSize: 10, fontWeight: 'bold', color: 'rgba(46, 46, 46, 0.8)' }}>
              Share
            </span>
          </div>
        </div>
      ),
    },
  ],
  locationAdmin: [
    {
      sortable: false,
      accessor: 'cover_photo',
      renderCell: cell => (
        <Image
          className="rounded"
          style={{ width: '120px', height: '80px', objectFit: 'cover' }}
          src={cell?.value || Placeholder}
        />
      ),
    },
    {
      Header: 'Salon Name',
      accessor: 'name',
      renderCell: cell => (
        <p className="m-0">
          {cell.row.original.name}
          <span className="m-0 ml-1 text-black-50">{cell.row.original.sap_id}</span>
        </p>
      ),
    },
    {
      Header: i18n.t('app:artists'),
      accessor: 'participants.length',
      // renderCell: cell => <p className="m-0 ml-2">{cell.row.original.participants.length}</p>,
    },
    {
      sortable: false,
      Header: i18n.t('app:entities.location'),
      accessor: 'address',
      renderCell: cell => {
        const nonNilAddress = _.mapValues(cell.row.original.address, value =>
          isNil(value) ? '' : value,
        );
        return (
          <p
            style={{ textTransform: 'capitalize' }}
            className="m-0 ml-2"
          >{`${nonNilAddress.street_name.toLowerCase()} ${nonNilAddress.post_town.toLowerCase()}, ${nonNilAddress.state.toUpperCase()} ${
            nonNilAddress.post_code
          }`}</p>
        );
      },
    },
    {
      Header: 'QR Code',
      accessor: 'qr_code',
      sortable: false,
      renderCell: cell => {
        const vanity = cell.row.original.vanity_url;
        if (!vanity) return null;
        return <QrCode type="location" shortId={vanity} />;
      },
    },
    {
      Header: i18n.t('app:views'),
      accessor: 'views_count',
    },
    {
      sortable: false,
      accessor: 'outerSite',
      renderCell: cell => {
        const vanity = cell.row.original.vanity_url;
        if (!vanity) return null;
        return (
          <Button
            variant="dark"
            className="rounded-0 text-center w-100"
            href={`${config.avedaWebApp}/salon/${cell.row.original.vanity_url}?preview=true`}
            target="_blank"
          >
            {i18n.t('ownerPortal:locations.ctaAdmin')}
          </Button>
        );
      },
    },
    {
      sortable: false,
      accessor: 'uid',
      renderCell: cell => (
        <Button
          variant="dark"
          className="rounded-0 text-center w-100"
          onClick={() => store.dispatch(push(`/location/${cell.value}/page-edit`))}
        >
          {i18n.t('ownerPortal:locations.cta')}
        </Button>
      ),
    },
  ],
  manager: [
    {
      Header: i18n.t('ownerPortal:salonInfo.managersOwners.img'),
      accessor: 'connected_user',
      renderCell: cell => (
        <Image
          className="rounded-circle bg-secondary"
          style={{ width: '40px', height: '40px' }}
          src={cell.row.original.connected_user?.picture || avedaPlaceholder}
        />
      ),
    },
    {
      Header: i18n.t('ownerPortal:salonInfo.managersOwners.user'),
      accessor: 'name',
      renderCell: cell => {
        const name = cell.row.original.connected_user?.name || cell.row.original.name;
        const surename = cell.row.original.connected_user?.last_name || cell.row.original.surename;
        const { email } = cell.row.original;
        return (
          <Col>
            <p className="m-0">{`${name} ${surename}`}</p>
            <p className="m-0 text-balck-50">{`${email}`}</p>
          </Col>
        );
      },
    },
    {
      Header: i18n.t('ownerPortal:salonInfo.managersOwners.role'),
      accessor: 'owner',
      renderCell: cell => {
        const color = cell.row.original.owner ? 'bg-warning' : 'bg-danger';
        const string = cell.row.original.owner ? 'owner' : 'manager';
        return <span className={`badge ${color}`}>{string}</span>;
      },
    },
  ],
  post: [
    {
      Header: 'Id',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:title'),
      accessor: 'title',
    },
    {
      Header: i18n.t('app:content'),
      accessor: 'body',
      renderCell: cell => (
        // eslint-disable-next-line
        <p className="m-0" dangerouslySetInnerHTML={{ __html: cell.value }} />
      ),
    },
    {
      Header: i18n.t('pages:theme.coverPhoto'),
      accessor: 'cover_image',
      renderCell: cell => (
        <Image
          src={cell.value}
          style={{ width: 160, height: 90, objectFit: 'cover' }}
          className="rounded"
        />
      ),
    },
    {
      accessor: 'uid',
      renderCell: cell => {
        // eslint-disable-next-line
        const dispatch = useDispatch();
        return (
          <Button
            className="rounded-0"
            size="sm"
            variant="dark"
            onClick={async () => {
              await EntityService.delete('learning_center_post', cell.value);
              dispatch(entityActions.fetchEntity(entities.learning_center));
              toastr.success('post successfully deleted!');
            }}
          >
            {i18n.t('app:delete')}
          </Button>
        );
      },
    },
  ],
  bundleDashboard: [
    {
      sortable: false,
      Header: i18n.t('app:name'),
      accessor: 'info.name',
      renderCell: cell => {
        const { auth } = store.getState();
        return (
          <a
            style={{ color: 'black' }}
            target="_blank"
            href={`${auth.user.brand.configuration.short_url}/${
              cell.row.original.info.short_id
            }?preview=true`}
          >
            {cell.value}
          </a>
        );
      },
    },
    {
      sortable: false,
      Header: i18n.t('app:owner'),
      accessor: 'info',
      renderCell: cell => {
        const name = cell.value?.owner_name || '';
        const surname = cell.value?.owner_surname || '';
        return <p className="m-0">{`${name} ${surname}`}</p>;
      },
    },
    {
      sortable: false,
      Header: i18n.t('app:views'),
      accessor: 'dcount',
    },
  ],
  retailerBundle: [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },
    {
      Header: i18n.t('pages:product.products'),
      accessor: 'productsCount',
    },

    {
      Header: i18n.t('app:views'),
      accessor: 'views',
    },
    {
      Header: i18n.t('columns:publishedAt'),
      accessor: 'created_at',
      renderCell: formatDate,
    },
    {
      Header: 'QR code',
      accessor: 'qr',
      renderCell: cell => {
        const { short_id } = cell.row.original;
        return <QrCode shortId={short_id} type="bundle" />;
      },
    },
    {
      Header: 'Preview',
      accessor: 'url',
      renderCell: cell => (
        <a
          target="_blank"
          className="border-0 btn-transition btn btn-outline-primary"
          href={`${cell.row.original.url}?preview=true`}
        >
          {cell.row.original.url}
        </a>
      ),
      sortable: false,
    },
  ],
  learning_center: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: 'Language',
      accessor: 'language',
    },
    {
      Header: 'Region',
      accessor: 'region',
      reference: { entity: entities.region, property: 'name' },
      sortable: false,
    },
  ],
  welcome_screen: [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: 'Language',
      accessor: 'language',
    },
    {
      Header: 'Region',
      accessor: 'region',
      reference: { entity: entities.region, property: 'name' },
      sortable: false,
    },
  ],
  look: [
    {
      Header: i18n.t('app:image'),
      sortable: false,
      accessor: 'pictures',
      renderCell: cell => (
        <Image
          className="rounded"
          style={{ width: '160px', height: '90px', objectFit: 'cover' }}
          src={cell.value[0] || Placeholder}
        />
      ),
    },
    {
      Header: 'id',
      accessor: 'created_at',
      hidden: true,
      renderCell: cell => new Date(cell.value).getTime(),
    },
    {
      Header: i18n.t('app:name'),
      accessor: 'name',
    },
    {
      Header: i18n.t('ownerPortal:Looks.enabled'),
      accessor: 'enabled',
      sortable: false,
      centered: true,
      renderCell: cell =>
        cell.value ? (
          <MdCheckCircle style={{ margin: '0 auto', display: 'block' }} size="20px" color="green" />
        ) : (
          <FaTimesCircle style={{ margin: '0 auto', display: 'block' }} size="18px" color="red" />
        ),
    },
    {
      Header: i18n.t('ownerPortal:Looks.visible'),
      accessor: 'is_visible',
      sortable: false,
      centered: true,
      renderCell: cell =>
        cell.value ? (
          <MdCheckCircle style={{ margin: '0 auto', display: 'block' }} size="20px" color="green" />
        ) : (
          <FaTimesCircle style={{ margin: '0 auto', display: 'block' }} size="18px" color="red" />
        ),
    },
    {
      Header: i18n.t('ownerPortal:Looks.draft'),
      accessor: 'is_draft',
      sortable: false,
      centered: true,
      renderCell: cell =>
        cell.value ? (
          <MdCheckCircle style={{ margin: '0 auto', display: 'block' }} size="20px" color="green" />
        ) : (
          <FaTimesCircle style={{ margin: '0 auto', display: 'block' }} size="18px" color="red" />
        ),
    },
    {
      Header: i18n.t('ownerPortal:Looks.productCount'),
      accessor: 'products_count',
      centered: true,
    },
    {
      Header: i18n.t('ownerPortal:Looks.viewsCount'),
      accessor: 'views_count',
      centered: true,
    },
  ],

  ourArtists: [
    {
      Header: 'Feature',
      accessor: 'checkbox',
      sortable: false,
    },
    {
      Header: 'Artist',
      accessor: 'name',
      renderCell: cell => (
        <div className="d-flex align-items-center">
          <img
            src={cell.row.original.image}
            style={{ width: 50, height: 50, marginRight: '10px' }}
            alt="product preview"
          />
          <p>{cell.row.original.name}</p>
        </div>
      ),
    },
    {
      Header: 'Salon',
      accessor: 'salonName',
      sortable: false,
    },
    {
      Header: 'Looks',
      accessor: 'looksCount',
      sortable: false,
    },
    {
      Header: 'Total Views',
      accessor: 'viewsCount',
      sortable: false,
    },
    {
      Header: i18n.t('columns:pageStatus'),
      accessor: 'profile_page_status',
      sortable: false,
      renderCell: cell => (
        <span className={`badge text-uppercase bg-${getStylistBadge(cell.row.original)}`}>
          {getPageStatusString(cell.row.original)}
        </span>
      ),
    },
    {
      Header: 'Preview',
      accessor: 'url',
      sortable: false,
      renderCell: cell =>
        cell.value ? (
          <a
            href={cell.value}
            target="_blank"
            rel="noopener"
            className="text-reset text-decoration-underline"
          >
            View page
          </a>
        ) : (
          '-'
        ),
    },
  ],
  customArtists: [
    {
      accessor: 'checkbox',
      sortable: false,
    },
    {
      Header: i18n.t(
        'ownerPortal:locations.addComponentModal.components.ourStylists.customStylistName',
      ),
      accessor: 'name',
      renderCell: cell => (
        <div className="d-flex align-items-center">
          <img
            src={cell.row.original.picture}
            style={{ width: 50, height: 50, marginRight: '10px' }}
            alt="user preview"
          />
          <p>{cell.row.original.name}</p>
        </div>
      ),
    },
    {
      Header: i18n.t(
        'ownerPortal:locations.addComponentModal.components.ourStylists.customStylistTitle',
      ),
      accessor: 'about',
      sortable: false,
    },
    {
      Header: i18n.t(
        'ownerPortal:locations.addComponentModal.components.ourStylists.customStylistActions',
      ),
      accessor: 'actions',
      sortable: false,
    },
  ],
  batchRetailer: [
    {
      Header: i18n.t('pages:retailers.name'),
      accessor: 'first_name',
      sortable: false,
    },
    {
      Header: i18n.t('pages:retailers.lastName'),
      accessor: 'last_name',
      sortable: false,
    },
    {
      Header: i18n.t('pages:retailers.email'),
      accessor: 'email',
      sortable: false,
    },
  ],
  shareLinks: [
    {
      sortable: false,
      accessor: 'url',
      renderCell: cell => (
        <Image
          className="rounded"
          style={{ width: '120px', height: '80px', objectFit: 'cover' }}
          src={cell?.value || Placeholder}
        />
      ),
    },
    {
      Header: 'Title',
      accessor: 'name',
    },
    {
      sortable: false,
      Header: i18n.t('ownerPortal:locations.description'),
      accessor: 'description',
    },
    {
      Header: i18n.t('app:actions'),
      sortable: false,
      accessor: 'owned',
      renderCell: cell => (
        // cell.row.original.owned
        <div className="d-flex" style={{ gap: '8px' }}>
          <SelectLocationAndAddModuleModal
            trigger={
              <div className="d-flex flex-column">
                <Button
                  variant="danger"
                  className="rounded-0 text-center position-relative"
                  style={{
                    padding: '6px 12px',
                    border: 'none',
                    outline: 'none',
                    lineHeight: '20px',
                    background: '#BC3C3C',
                  }}
                >
                  <MdOutlineAddToPhotos color="white" size={20} style={{ paddingRight: 0 }} />
                </Button>
                <span className="text-small text-black-50 text-center">Add module</span>
              </div>
            }
            id={cell.row.original.uid}
          />
          <div className="d-flex flex-column">
            <Button
              variant="light"
              className="rounded-0 text-center"
              style={{
                background: 'white',
                padding: '6px 12px',
                outline: 'none',
                lineHeight: '20px',
                border: '1px solid var(--neutrals-500, #737373)',
              }}
              onClick={() => {
                window.open(cell.row.original.link, '_blank');
              }}
            >
              <MdOutlineRemoveRedEye color="black" size={20} style={{ paddingRight: 0 }} />
            </Button>
            <span className="text-small text-black-50 text-center">Preview</span>
          </div>
          {false && (
            <ShareLinkModal
              trigger={
                <div className="d-flex flex-column">
                  <Button
                    variant="primary"
                    className="rounded-0 text-center"
                    style={{
                      padding: '6px 12px',
                      border: 'none',
                      outline: 'none',
                      lineHeight: '20px',
                    }}
                  >
                    <IoMdShare color="white" size={20} style={{ paddingRight: 0 }} />
                  </Button>
                  <span className="text-small text-black-50 text-center">Share</span>
                </div>
              }
              url={cell.row.original.link}
            />
          )}
        </div>
      ),
    },
  ],
};

export default columns;
